import { useState, useEffect } from "react";
import styled from "@emotion/styled";
import Video from "twilio-video";

import Track from "./Track";
import EnvVars from "../../config/EnvVars";
import ProductEnum from "../../enums/ProductEnum";
import LocalizedStrings from "../../localizations/LocalizedStrings";

type StateTrack = Video.LocalTrack | Video.RemoteTrack;

interface IProps {
  participant: Video.Participant;
  sinkId?: string;
  showParticipantList?: boolean;
}

const RemoteParticipant = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
const Identity = styled.p<{ product: ProductEnum }>`
  color: white;
  position: absolute;
  font-family: "Inter";
  top: ${({ product }) =>
    product === ProductEnum.CopilotIQ ? "10px" : undefined};
  left: ${({ product }) => (product === ProductEnum.CopilotIQ ? "0px" : "5px")};
  right: ${({ product }) =>
    product === ProductEnum.CopilotIQ ? "0px" : undefined};
  bottom: ${({ product }) =>
    product === ProductEnum.CopilotIQ ? undefined : "20px"};
  text-align: center;
  text-shadow: 1px 1px 2px black;
`;

const NoTrackView = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: black;
`;

const Participant = ({
  participant,
  sinkId,
  showParticipantList = false
}: IProps) => {
  const [tracks, setTracks] = useState<StateTrack[]>([]);

  const onTrackSubscribed = (track: StateTrack) => {
    console.log(
      participant.identity,
      "Track subscribed %s %s",
      track.name,
      track.kind
    );

    setTracks((previousInputs) => {
      if (previousInputs.findIndex((item) => item.name === track.name) !== -1)
        return previousInputs;

      return [...previousInputs, track];
    });
  };

  const onTrackUnsubscribed = (track) => {
    console.log(participant.identity, "Track unsubscribed %s", track.name);

    try {
      track.detach().forEach((element) => element.remove());
    } catch (e) {
      console.log(e);
    }

    setTracks((previousInputs) =>
      previousInputs.filter((item) => item.name !== track.name)
    );
  };

  useEffect(() => {
    participant.on("trackSubscribed", onTrackSubscribed);
    participant.on("trackUnsubscribed", onTrackUnsubscribed);

    participant.tracks.forEach((publication: Video.RemoteTrackPublication) => {
      if (publication.isSubscribed) {
        onTrackSubscribed(publication.track);
      }
    });
    return () => {
      participant.off("trackSubscribed", onTrackSubscribed);
      participant.off("trackUnsubscribed", onTrackUnsubscribed);

      participant.tracks.forEach(
        (publication: Video.RemoteTrackPublication) => {
          if (publication.isSubscribed) {
            onTrackUnsubscribed(publication.track);
          }
        }
      );
    };
  }, [participant]);

  const hasVideo = tracks?.find((item) => item.kind === "video") !== undefined;
  const noVideoText =
    EnvVars.REACT_APP_PRODUCT === ProductEnum.RemoteIQ
      ? LocalizedStrings.videoCall.memberTurnedOffVideo
      : LocalizedStrings.videoCall.carerTurnedOffVideo;

  // Identity in format "{userId};{fullName}"
  const identityArray = participant?.identity?.split(";");
  const disableIdentity =
    showParticipantList && EnvVars.REACT_APP_PRODUCT === ProductEnum.RemoteIQ;

  return (
    <RemoteParticipant className="participant" id={participant.identity}>
      {!hasVideo && <NoTrackView>{noVideoText}</NoTrackView>}
      {tracks?.map((track) => {
        return (
          <Track
            key={track.name}
            track={track}
            sinkId={sinkId}
            isLocal={false}
          />
        );
      })}
      {!disableIdentity && (
        <Identity
          className="identity"
          product={EnvVars.REACT_APP_PRODUCT as ProductEnum}
        >
          {identityArray[1]}
        </Identity>
      )}
    </RemoteParticipant>
  );
};

export default Participant;
