import React, { useEffect, useState } from "react";
import { StyleSheet, Text, Platform } from "react-native";
import styled from "@emotion/styled";
import { DateTime } from "luxon";

import { Typography } from "@mui/material";

const StyledTypography = styled(Typography)`
  font-family: "Inter";
  text-shadow: 1px 1px 2px black;
`;

const CallTimer = () => {
  const [startedDate] = useState<DateTime>(DateTime.now());
  const [currentDate, setCurrentDate] = useState<DateTime>(DateTime.now());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(DateTime.now());
    }, 100);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const duration = currentDate.diff(startedDate).toFormat("mm:ss");

  if (Platform.OS === "web")
    return (
      <StyledTypography variant="body1" color="white">
        {duration}
      </StyledTypography>
    );
  else return <Text style={styles.text}>{duration}</Text>;
};

const styles = StyleSheet.create({
  text: {
    color: "white",
    fontSize: 16,
    textShadowColor: "black",
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 1
  }
});

export default CallTimer;
